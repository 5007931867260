import {Dialog} from "./common";

export const action = "add-comment";

export class DialogBox extends Dialog{
	open(action){
		//we get cell only on hover commented cell
		this.cell = action.cell ? action.cell : this.view.getSelectedId();

		// cell not provided
		if (!this.cell || 
			// cell is locked and in the edit mode
			(this.view.isCellLocked(this.cell.row, this.cell.column) && !action.viewonly) ||
			// action blocked by user's code
			!(this.view.callEvent("onBeforeCommentShow", [this.cell.row, this.cell.column, !action.viewonly]))){
			return false;
		}

		this.view.comments._activeComment = {editStatus: !action.viewonly, cell: this.cell};

		if(!this.$dialog){
			this.$dialog = webix.ui(this.$init());
			this.view._destroy_with_me.push(this.$dialog);
			this.view.comments.commentsView = this.$dialog;
			this.$dialog.attachEvent("onHide", ()=>this.$hide());
			this.view.attachEvent("onCommentHide", ()=>this.$dialog.hide());
		}

		const form = this.$dialog.getBody();

		const span = this.view.$$("cells").getSpan(this.cell.row, this.cell.column);
		const item = span ? {row: this.cell.row, column:(span[1]*1+span[2]-1)} : this.cell;

		webix.delay(()=>{
			this.$dialog.show(this.view.$$("cells").getItemNode(item));
			this._setComment();
		});
		if (this.$show(this.$dialog, form) === false)
			this.close();
	}
	$show(){
		this.textarea = this.$dialog.queryView({view:"textarea"});
		this.template = this.$dialog.queryView({view:"template"});
	}
	$init(){
		return {
			view: "popup",
			css:"ssheet_comments",
			minWidth:250,
			minHeight:150,
			relative:"right",
			resize:true,
			on:{
				onViewResize:()=>{
					this.$dialog.hide();
					this.$dialog.show();
					if(this.textarea.isVisible())
						this.textarea.focus();
				}
			},
			body:{
				animate:false,
				cells:[
					{
						view: "template",
						css:"ssheet_comment_view",
						borderless: true,
						scroll:"auto",
						onClick:{
							"ssheet_comment_view":() => {
								if(!(this.view.config.readonly || this.view.isCellLocked(this.cell.row, this.cell.column)))
									this.showInput(this.view.comments.get(this.cell.row, this.cell.column));
							}
						}
					},
					{
						view: "textarea",
						on:{
							onChange:()=>{
								this.addComment();
							},
							onFocus:()=>{
								this.view.comments._activeComment = {editStatus: true, cell: this.cell};
							},
							onBlur:()=>{
								this.view.comments._activeComment = {};
								this.$dialog.hide();
							}
						}
					}
				]
			}
		};
	}
	_setComment(){
		const comment = this.view.comments.get(this.cell.row, this.cell.column);
		if(this.view.comments._activeComment.editStatus)
			this.showInput(comment);
		else
			this.showTemplate(comment);
	}
	showTemplate(text){
		this.template.show();
		this.template.setHTML(text);
	}
	showInput(text){
		this.view.$$("cells").select(this.cell.row, this.cell.column);
		webix.delay(()=>{
			this.changeTextarea(text);
			this.textarea.show();
			this.textarea.focus();
		});
	}
	addComment(){
		this.view.comments.add(this.cell.row, this.cell.column, this.textarea.getValue());
	}
	changeTextarea(text){
		this.textarea.blockEvent();
		this.textarea.setValue(text||"");
		this.textarea.unblockEvent();
	}
}
export function setCellEditor(row, column, editor) {
	const line = this._table._ssEditors[row] = this._table._ssEditors[row] || {};
	const value = line[column] || null;
	line[column] = editor;

	if (editor && editor.editor){
		this._table.addCellCss(row,column, "ss_editor");
	} else {
		delete this._table._ssEditors[row][column];
		this._table.removeCellCss(row,column, "ss_editor");
	}
	this.callEvent("onAction", ["dropdown", { row, column, value, newValue:editor }]);
}

export function getCellEditor(row, column){
	let line = this._table._ssEditors[row] || {};
	return line[column] || null;
}
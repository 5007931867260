import {split} from "./parser";

function updateSheetNames(view, formula, oldName, newName){
	if(formula.indexOf("!") == -1)
		return formula;

	const stack = split(formula, view, true);
	for(let i = 0; i < stack.length; i++){
		if(webix.isArray(stack[i])){
			const thisSheet = stack[i][0] === oldName;
			let sheet = thisSheet ? newName : stack[i][0];
			if(sheet.indexOf(" ") != -1)
				sheet = `'${sheet}'`;
			stack[i] = `${sheet}!${stack[i][1]}`;
		}
	}
	return stack.join("");
}

function isFormula(val){
	return typeof val == "string" && val[0] == "=";
}

export function init(view){
	view.attachEvent("onSheetRename", (name, newName) => {
		view._sheets.forEach(sheet => {
			const activeSheet = sheet.name == view.getActiveSheet();
			if(activeSheet)
				sheet.content = view.serialize(); //actual data

			if(sheet.content.editors){
				sheet.content.editors.forEach(value => {
					const options = value[2].options;
					if(options){
						if(typeof options == "string")
							value[2].options = updateSheetNames(view, options, name, newName);
						else
							for (let i = 0; i < options.length; i++){
								const option = options[i];
								if(typeof option == "string"){
									if(isFormula(option))
										options[i] = updateSheetNames(view, option, name, newName);
								}
								else if(isFormula(option.id))
									option.id = updateSheetNames(view, option.id, name, newName);
							}
					}
				});
			}

			if(sheet.content.conditions){
				sheet.content.conditions.forEach(value => {
					if(isFormula(value[3]))
						value[3] = updateSheetNames(view, value[3], name, newName);
				});
				if(activeSheet){
					view.conditions.clear();
					view.conditions.parse( sheet.content.conditions );
				}
			}
		});
	});
}
import {isDateInvalid} from "../operations/formats";

function getText(text){
	var locale = webix.i18n.spreadsheet.labels;
	return locale[text] || text;
}
export function getDefaultStyles(){
	return [
		{name: getText("normal"), css: "webix_ssheet_condition_regular"},
		{name: getText("neutral"), css: "webix_ssheet_condition_neutral"},
		{name: getText("bad"), css: "webix_ssheet_condition_bad"},
		{name: getText("good"), css: "webix_ssheet_condition_good"}
	];
}

function isDate(value){
	return value && !isNaN(value) && !isDateInvalid(value);
}

export const conditions = {
	number:[
		{ id:"greater", batch:"text", handler:function(a,b){
			return a !== "" && (a*1 > b*1);
		}},
		{ id:"less", batch:"text", handler:function(a,b){
			return a !== "" && (a*1 < b*1);
		}},
		{ id:"greaterOrEqual", batch:"text", handler:function(a,b){
			return a !== "" && (a*1 >= b*1);
		}},
		{ id:"lessOrEqual", batch:"text", handler:function(a,b){
			return a !== "" && (a*1 <= b*1);
		}},
		{ id:"equal", batch:"text", handler:function(a,b){ return a == b; }},
		{ id:"notEqual", batch:"text", handler:function(a,b){ return a != b; }},
		{ id:"contains", batch:"text", handler:function(a,b){
			return a.toString().toLowerCase().indexOf(b.toString().toLowerCase()) !== -1;
		}},
		{ id:"notContains", batch:"text", handler:function(a,b){
			return a.toString().toLowerCase().indexOf(b.toString().toLowerCase()) === -1;
		}}
	],
	text: [
		{ id:"contains", batch:"text", handler:function(a,b){
			return a.toString().toLowerCase().indexOf(b.toString().toLowerCase()) !== -1;
		}},
		{ id:"notContains", batch:"text", handler:function(a,b){
			return a.toString().toLowerCase().indexOf(b.toString().toLowerCase()) === -1;
		}},
		{ id:"equal", batch:"text", handler:function(a,b){
			return a.toString().toLowerCase() === b.toString().toLowerCase();
		}},
		{ id:"notEqual", batch:"text", handler:function(a,b){
			return a.toString().toLowerCase() !== b.toString().toLowerCase();
		}},
		{ id:"beginsWith", batch:"text", handler:function(a,b){
			return a.toString().toLowerCase().lastIndexOf(b.toString().toLowerCase(), 0) === 0;
		}},
		{ id:"notBeginsWith", batch:"text", handler:function(a,b){
			return a.toString().toLowerCase().lastIndexOf(b.toString().toLowerCase(), 0) !== 0;
		}},
		{ id:"endsWith", batch:"text", handler:function(a,b){
			a = a.toString();
			b = b.toString();
			return a.toLowerCase().indexOf(b.toLowerCase(), a.length - b.length) !== -1;
		}},
		{ id:"notEndsWith", batch:"text", handler:function(a,b){
			a = a.toString();
			b = b.toString();
			return a.toLowerCase().indexOf(b.toLowerCase(), a.length - b.length) === -1;
		}}
	],
	date: [
		{ id:"greater", batch:"datepicker", handler: function(a, b){
			return isDate(a) && a > b;
		}},
		{ id:"less", batch:"datepicker", handler: function(a, b){
			return isDate(a) && a < b;
		}},
		{ id:"greaterOrEqual", batch:"datepicker", handler: function(a, b){
			return isDate(a) && a >= b;
		}},
		{ id:"lessOrEqual", batch:"datepicker", handler: function(a, b){
			return isDate(a) && a <= b;
		}},
		{ id:"equal", batch:"datepicker", handler: function(a, b){
			return isDate(a) && a == b;
		}},
		{ id:"notEqual", batch:"datepicker", handler: function(a, b){
			return !isDate(a) || a != b;
		}},
		{ id:"between", batch: "daterangepicker", handler: function(a, b){
			if (!isDate(a)) return false;
			return (!b.start || a > b.start) && (!b.end || a < b.end);
		}},
		{ id:"notBetween", batch: "daterangepicker", handler: function(a, b){
			if (!isDate(a)) return false;
			return (!b.start || a <= b.start) || (!b.end || a >= b.end);
		}}
	]
};
import * as box from "./imagecell";

export const action = "add-image-top";

export class DialogBox extends box.DialogBox{
	open(action){
		this.config = action.config;
		this.viewid = action.viewid;

		this.view.callEvent("onUIEditStart", [this.viewid, "image"]);

		box.DialogBox.prototype.open.apply(this, arguments);
	}
	$hide(){
		this.view.callEvent("onUIEditStop", [this.viewid, "image"]);
		this.config = this.viewid = null;
	}
	$init(){
		this.view.attachEvent("onUIEditStart", (id, type)=>{
			if(this.$dialog && this.$dialog.isVisible() && type != "image")
				this.close();
		});

		this.view.attachEvent("onCommand", obj =>{
			if(obj.id == "close-ui-editor" && this.$dialog && this.$dialog.isVisible())
				this.close();
		});

		return box.DialogBox.prototype.$init.apply(this, arguments);
	}
	$show(box, form){

		form.elements.preview.setHTML("");
		form.elements.url.setValue("");

		if(this.config && this.config.data)
			form.elements.url.setValue(this.config.data);

		form.elements.url.focus();
	}
	okClick(){
		const image = this.fillUrl(this.$dialog.getBody().elements.url.getValue());
		if(this.viewid)
			this.view.views.update(this.viewid, image);
		else{
			const id = this.view.views.add(null, null, "image", image);
			this.view.views.highlight(id);
		}
		this.close();
	}
}
webix.protoUI({
	$cssName: "window",
	name: "ssheet-dialog",
	$init: function(config){
		this.$view.className += " webix_ssheet_dialog";
		this.config.buttons = config.buttons;
		this.config.close = true;
	},
	getBodyConfig: function(content){
		content.borderless = true;
		var elements = this.getFormElements(content);
		var config = {
			view: "form",
			css: "webix_ssheet_form",
			padding: webix.skin.$active.layoutPadding.form,
			elements: elements
		};
		return config;
	},
	getFormElements: function(content){
		var elements;
		if(webix.isArray(content))
			elements= content;
		else{
			elements = [];
			elements.push(content);
		}
		if(this.config.buttons){
			elements.push({height:1});
			elements.push( {
				margin: 10,
				cols:[
					{},
					{view: "button", css: "ssheet_cancel_button", label: webix.i18n.spreadsheet.labels.cancel, autowidth:true, click: function(){
						var parentView = this.getTopParentView();
						parentView.callEvent("onCancelClick",[]);
					}},
					{view: "button", label: webix.i18n.spreadsheet.labels.save, hotkey: "enter", autowidth:true, click: function(){
						var parentView = this.getTopParentView();
						parentView.callEvent("onSaveClick",[]);
					}}
				]
			});
		}
		return elements;
	},
	body_setter: function(value){
		if(typeof value == "object"){
			if(webix.isUndefined(value.padding))
				value.padding = webix.skin.$active.layoutPadding.form;
			if(value.view == "form" && value.elements){
				value.elements = this.getFormElements(value.elements);
				if(!value.css)
					value.css = "webix_ssheet_form";
			}
			else
				value = this.getBodyConfig(value);
		}

		return webix.ui.window.prototype.body_setter.call(this,value);
	},
	defaults:{
		move:true,
		head: "Dialog",
		buttons: true,
		close:true,
		//we need to retain focus in table area, so disabling the autofocusing feature
		autofocus:false,
		width: 350
	}
}, webix.ui.window, webix.IdSpace);



webix.protoUI({
	$cssName: "datatable",
	name: "ssheet-dialog-table",
	$init: function(config){
		if(!config.headerRowHeight)
			config.headerRowHeight = 34;
		this.$view.className += " webix_ssheet_dialog_table";
	}
},webix.ui.datatable);
import {Dialog} from "./common";
import * as flt from "../operations/filter";

export const action = "start-filter";

export class DialogBox extends Dialog{
	open(action){
		this.cell = action.cell;
		this.$mode = action.mode;
		this.$filter = action.filter;

		// cell not provided
		if (!this.cell || 
			// cell is locked
			(this.view.isCellLocked(this.cell.row, this.cell.column))){
			return false;
		}

		if (!this.$dialog){
			this.$dialog = webix.ui(this.$init());
			this.view._destroy_with_me.push(this.$dialog);
			this.$dialog.attachEvent("onHide", () => this.$hide());

			const table = this.view.$$("cells");
			table.attachEvent("onScrollY", () => this.syncScroll());
			table.attachEvent("onScrollX", () => this.syncScroll());
		}

		if (this.$show(this.$filter.value, action.options) === false)
			return this.close();
		this.$dialog.show( this._getTargetNode() );
	}
	_getTargetNode(){
		const table = this.view.$$("cells");
		const span = table.getSpan(this.cell.row, this.cell.column);
		return span ? table.getSpanNode(this.cell) : table.getItemNode(this.cell);
	}
	$show(value, options){
		const list = this.getList();
		const filter = this.getFilter();

		if (filter.config.mode !== this.$mode){
			filter.define("conditions", this.getConditions(this.$mode));
			filter.define("mode", this.$mode);
		}

		// if the filter mode for the current column has been changed - reset the value
		const type = value && value.conditions && value.conditions.type;
		if (type && !flt.getSingleOption(this.$mode, type))
			value = "";

		list.clearAll();
		list.data.importData(options);

		// filter data has been changed - reset value
		filter.config.value = "";
		filter.setValue( webix.copy(value||{}) );
	}
	$init(){
		const locale = webix.i18n.spreadsheet.labels;
		return {
			view:"popup",
			css:"ssheet_filter",
			padding:7,
			relative:"right",
			body:{
				margin:4,
				rows:[
					{
						view:"filter",
						field:"value",
						mode:"text",
						conditions:this.getConditions("text"),
						inputs:[
							"text",
							{ view:"ssheet-datepicker", timepicker:true, batch:"datepicker", on:{
								onChange:() => this.getFilter().applyFilter()
							}},
							{ view:"ssheet-daterangepicker", timepicker:true, batch:"daterangepicker", on:{
								onChange:() => this.getFilter().applyFilter()
							}}
						],
						template:function(obj){
							return obj.$value || obj.value;
						}
					},
					{ cols:[
						{ },
						{ view:"button", label:locale["cancel"], autowidth:true, css:"webix_transparent", on:{
							"onItemClick": () => this.close()
						}},
						{ view:"button", label:locale["apply"], autowidth:true, css:"webix_secondary", on:{
							"onItemClick": () => this.applyFilter()
						}},
					]}
				]
			}
		};
	}
	applyFilter(){
		const filter = this.getFilter();
		const oldValue = this.$filter.value;
		const value = this.$filter.value = filter.getValue();
		this.$filter.handler = flt.getFilterFunction(this.$mode, value);

		this.view.filterSpreadSheet();
		this.view.callEvent("onAction", ["apply-filter", { row:this.cell.row, column:this.cell.column, value:oldValue, newValue:value }]);
		this.setCellStyle(this.cell.row, this.cell.column, "ss_filter_active", flt.isValueActive(value));

		this.close();
	}
	setCellStyle(row, column, css, add){
		const table = this.view.$$("cells");
		const span = this.getSpan(table, row, column);

		if (span){
			if (add){
				if (span[3].indexOf(css) == -1)
					span[3] += " " + css;
			} else {
				span[3] = span[3].replace(" " + css, "");
			}
		}
		table[add?"addCellCss":"removeCellCss"](row, column, css);
	}
	getSpan(table, row, column){
		let item = table.getSpan()[row];
		if (item)
			return item[column];
	}
	getList(){
		return this.getFilter().getChildViews()[2];
	}
	getFilter(){
		return this.$dialog.getBody().getChildViews()[0];
	}
	syncScroll(){
		if (this.$dialog.isVisible()){
			const node = this._getTargetNode();
			const table = this.view.$$("cells");

			let isHidden = false;
			if (node){
				const nodeOffset = webix.html.offset( node );
				const tableOffset = webix.html.offset( table.$view.getElementsByClassName("webix_ss_body")[0] );
				const ydiff = nodeOffset.y - tableOffset.y;
				const xdiff = nodeOffset.x - tableOffset.x + nodeOffset.width;

				isHidden = (ydiff < 0 || ydiff+nodeOffset.height > tableOffset.height || 
					xdiff < 0 || xdiff-nodeOffset.width > tableOffset.width - webix.env.scrollSize);
			}
			if (!node || isHidden)
				this.$dialog.show({x:-9999, y:-9999});
			else 
				this.$dialog.show(node);
		}
	}
	getConditions(name){
		const locale = webix.i18n.filter;
		const conditions = flt.getConditions(name);

		for (let i=0; i<conditions.length; i++)
			if (conditions[i].id)
				conditions[i].value = locale[conditions[i].id];

		return conditions;
	}
}
import {Dialog} from "./common";
import {parseImage} from "../mathematics/writer";

export const action = "add-image-cell";

export class DialogBox extends Dialog{
	$show(box, form){
		this.cell = this.view.getSelectedId();
		if(!this.cell)
			return false;

		form.elements.preview.setHTML("");
		form.elements.url.setValue("");

		//sync state of dialog to data with  the selected cell
		let value = this.view.getCellValue(this.cell.row, this.cell.column);
		let data = parseImage(value);
		if (data && data.url)
			form.elements.url.setValue(data.url);

		form.elements.url.focus();
	}
	$init(){
		const save = this.view.config.save;
		const server = (save && save.images) || null;

		return {
			view: "ssheet-dialog",
			head: webix.i18n.spreadsheet.labels["image-title"],
			move: true,
			position: "center",
			body:{
				view:"form",
				elements:[
					{ view:"text", name:"url",  placeholder:webix.i18n.spreadsheet.labels["image-url"], on:{
						"onChange":(image) => this.showPreview(image)
					}},
					{ view:"label", label:webix.i18n.spreadsheet.labels["image-or"], align: "center"},
					{ view:"uploader", label:webix.i18n.spreadsheet.labels["image-upload"], upload:server, on:{
						onBeforeFileAdd: function(file){
							if (!this.config.upload){
								const reader = new FileReader();
								reader.onload = (e) => this.getFormView().elements.url.setValue(e.target.result);
								reader.readAsDataURL(file.file);
								return false;
							}
							else
								this.getFormView().elements.preview.setHTML("");
						},
						onFileUpload: function(item, response){
							this.getFormView().elements.url.setValue(response.imageURL);
						}
					}},
					{ view:"formlate", name:"preview", borderless: true, css: "webix_ssheet_preview", template:"", height:50 }

				]
			},
			on:{
				onSaveClick: () => this.okClick(),
				onCancelClick: () => this.close()
			}
		};
	}
	okClick(){
		const cell = this.cell;
		const image = this.fillUrl(this.$dialog.getBody().elements.url.getValue());
		this.view.addImage(cell.row, cell.column, image);
		this.close();
	}
	fillUrl(url){
		if (!/^[/]|((https?:\/|data:image)\/)/i.test(url))
			url = "http://" + url;
		return url;
	}
	showPreview(image){
		if (image){
			image = webix.template.escape(this.fillUrl(image));
			this.$dialog.queryView("form").elements.preview.setHTML("<img class='webix_ssheet_cimage' src='"+image+"'></img>");
		}
	}
}
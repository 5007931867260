import {expandSelection} from "../helpers/expand_selection";
import {rangeObj} from "../helpers/column_names";
import group from "./undo_group";
import {_changeCondition} from "./conditional_formats";

export function init(view){
	view.attachEvent("onCommand", (cm) => {
		if (cm.id === "sort-asc" || cm.id === "sort-desc")
			sortRange(view, null, cm.id.replace("sort-",""));
	});
}

const sorters = {
	"int":function(dir, key){
		return (a,b) => {
			if (!b[key]) return -1;
			if (!a[key]) return 1;

			let c = parseFloat(a[key]) || dir*Infinity;
			let d = parseFloat(b[key]) || dir*Infinity;
			return (c > d ? 1 : (c == d ? 0 : -1))*dir;
		};
	},
	"str":function(dir, key){
		return (a,b) => {
			if (!b[key]) return -1;
			if (!a[key]) return 1;

			let c = a[key].toString().toLowerCase();
			let d = b[key].toString().toLowerCase();
			return (c > d ? 1 : (c == d ? 0 : -1))*dir;  
		};
	}
};

export function sortRange(view, range, dir){
	range = range || view._table.getSelectArea();

	if (range){
		range = rangeObj(range, view);
		let fullrange = range;
		if (range.start.row === range.end.row)
			fullrange = expandSelection(range, view);

		const key = view.getCellValue(range.start.row, range.start.column, false);
		const type = isNaN(parseFloat(key)) ? "str" : "int";

		dir = (!dir || dir === "asc") ? 1 : -1;
		group.set(()=>
			sort(fullrange, range.start.column, type, dir, view)
		);
	}
}

function sort(range, by, type, dir, view){
	for (let column = range.start.column; column <= range.end.column; column++){
		//get all selected column values
		const values = [];
		for (let row = range.start.row; row <= range.end.row; row++) {
			if(view.isRowVisible(row)){
				const value = view.getCellValue(row, column, false);
				const style = view.getStyle(row, column);

				let math = view.getCellValue(row, column);
				math = math && math[0] == "=" ? math : null;

				const conditions = view.conditions.get(row,column);
				if(conditions)
					_changeCondition("remove", row, column, conditions, null, view);

				values.push({
					value,
					style,
					math,
					row,
					conditions
				});
			}
		}

		//sort them
		values.sort(sorters[type](dir, "value"));

		//set new values to original column
		for (let row = range.start.row; row <= range.end.row; row++) {
			if(view.isRowVisible(row)){
				const element = values.shift();

				view.setStyle(row, column, (element.style || null));
				if(element.conditions)
					_changeCondition("update", row, column, null, element.conditions, view);

				if (!element.math) view.setCellValue(row, column, element.value);
				else {
					const newMath = view._mData.getStore().transposeMath(element.math, row - element.row, 0);
					view.setCellValue(row, column, newMath);
				}
			}
		}
	}

	view.refresh();
}
webix.editors.ss_richselect = webix.extend({
	getFormat:function(value){
		const list = this.getInputNode();
		let item;

		if (value && list.exists(value))
			item = list.getItem(value);
		return item && item.format;
	},
	popupInit:function(popup){
		webix.editors.richselect.popupInit.apply(this, arguments);

		popup.getList().define("template", function(obj){
			return obj.$value || obj.value;
		});
	}
}, webix.editors.richselect);
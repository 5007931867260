import {Dialog} from "./common";
export const action = "resize";

export class DialogBox extends Dialog{
	open(action){
		this.group = action.group;
		this.header = action.value;
		Dialog.prototype.open.apply(this, arguments);
	}
	$show(){
		const sel = this.view.getSelectedId();
		if(!sel)
			return false;
		this.view.$handleSelection = ()=>{
			return false;
		};

		const elements = this.$dialog.getBody().elements;
		this.type = elements["type"];
		this.size = elements["size"];

		this.$dialog.getHead().getChildViews()[0].setHTML(this.header);
		const grid = this.view.$$("cells");

		let value;
		if(this.group == "row")
			value = grid.getItem(sel.row).$height || grid.config.rowHeight;
		else
			value = grid.getColumnConfig(sel.column).width || grid.config.columnWidth;

		this.type.getOption(1).value = webix.i18n.spreadsheet.labels[this.group == "row" ? "height" : "width"];
		this.type.setValue(1);
		this.type.refresh();

		this.size.setValue(value);
		this.size.focus();
	}
	$hide(){
		this.view.$handleSelection = null;
	}
	okClick(){
		const type = this.type.getValue();

		let size = "auto";
		if(type == 1)
			size = this.size.getValue()*1;
		else if(type == 3)
			size = this.view.$$("cells").config[this.group == "row" ? "rowHeight" : "columnWidth"];

		if(this.group == "row")
			this.view.setRowHeight(null, size);
		else
			this.view.setColumnWidth(null, size);

		this.close();
	}
	$init(){
		const locale = webix.i18n.spreadsheet.labels;
		return {
			view: "ssheet-dialog",
			position:"center",
			width: 300,
			move: true,
			body:{
				padding:0,
				cols:[
					{
						view:"radio", name:"type", vertical:true, optionHeight:40,
						on:{
							onChange: id => {
								if(id == 1)
									this.size.enable();
								else
									this.size.disable();								
							}
						},
						options:[
							{id:1, value: ""},
							{id:2, value: locale["fit-content"]},
							{id:3, value: locale["default-size"]},
						]
					},
					{rows: [
						{view:"text", name: "size", width:120},
					]}
				]
			},
			on:{
				onSaveClick: () => this.okClick(),
				onCancelClick: () => this.close()
			}
		};
	}
}
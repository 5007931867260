export function find(arr, func){
	var result = findIndex(arr, func);
	return arr[result];
}

export function findIndex(arr, func){
	var result = -1;
	for(var i =0; result<0 && i < arr.length; i++){
		if(func(arr[i]))
			result = i;
	}
	return result;
}

export function getDimension(data, spans, rows, cols){
	for(let i=0; i<data.length; i++){
		// data[i] - [ row, column, value, style]
		let line = data[i];
		if (line[0]>rows) rows=line[0];
		if (line[1]>cols) cols=line[1];
	}

	if(spans)
		for(let i = 0; i < spans.length; i++){
			// spans[i] - [ row, column, colCount, rowCount ]
			const spanEndRow = spans[i][0] + spans[i][3] - 1;
			const spanEndCol = spans[i][1] + spans[i][2] - 1;

			if(spanEndRow > rows) rows = spanEndRow;
			if(spanEndCol > cols) cols = spanEndCol;
		}

	return [rows*1, cols*1];
}